import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.css';

const autocompleteOptions = {
    source: (request, response) => {
        const search = request.term;

        const formData = new FormData();
        formData.append('search_fetch', '1');

        fetch('/search.php?search=' + search, {
            method: 'POST',
            body: formData,
        })
            .then((res) => res.json())
            .then((matches) => response(matches.map((item) => {
                return {
                    ...item,
                    label: item.name,
                    value: item.name,
                };
            })));
    },
    minLength: 2,
};

// search general
$('[type="search"]').each(function () {
    const $this = $(this);

    $this
        .autocomplete({
            ...autocompleteOptions,
        })
        .autocomplete('instance')._renderItem = (ul, item) => {
            const content = `<img src="${item.picture}" alt="${item.name}"> ${item.name}`;

            return $('<li>')
                .append(content)
                .appendTo(ul);
        };
})

// search in header
$('[name="search"]')
    .autocomplete({
        ...autocompleteOptions,
        select: (event, ui) => {
            window.location = '/product_view.php?id=' + ui.item.id
        }
    });
